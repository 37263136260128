<template>
  <div class="jsmb-bateau-stockeur">
    <div class="section">
      <div class="container">
        <div class="columns jsmb-detail-entete-section is-vcentered">
          <div class="column is-8">
            <h2 class="is-size-4 is-uppercase">votre bateau stockeur</h2>
            <Separator class="push-bottom image is-32x32" color="primary"/>
            <p>{{user.bio}}</p>
          </div>
          <div class="column is-4 has-text-centered">
            <figure class="image is-128x128 is-horizontal-center">
              <img
                :src="user.profil_picture!==null?user.profil_picture:profilPicture"
                class="is-rounded"
                :alt="'Votre Bateau stockeur '+user.first_name"
                :title="'Votre Bateau stockeur '+user.first_name"
              >
            </figure>
            <p class="jsmb-bateau-stockeur-nom is-uppercase">
              Votre Bateau stockeur
              <br>
              {{user.first_name}}
            </p>
            <div class="field is-messages">
              <CommentQuestionModal text="Laisser un commentaire" action="comment" :toUserId="user.user_id" />
              <CommentQuestionModal style=" margin-left: 1rem !important;" text="Poser une question" action="question" :toUserId="user.user_id" :spaceId="user.space_id" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns jsmb-detail-entete-section is-vcentered">
          <div class="column is-12">
            <h2 class="is-size-4 is-uppercase">votre location</h2>
            <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
          </div>
        </div>
        <div class="columns is-vcentered">
          <div class="column is-8">
            <p>{{ad.description}}</p>
          </div>
          <div class="column is-4 has-text-centered"></div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns jsmb-detail-annonce has-text-centered">
          <div class="column">
            <h3 class="is-size-5 is-uppercase">Type de location</h3>
            <br>
            <span class="jsmb-detail-focus">{{ad.space_type}}</span>
          </div>
          <div class="column">
            <h3 class="is-size-5 is-uppercase">Type d’espace</h3>
            <br>
            <span class="jsmb-detail-focus">{{ad.location_type}}</span>
          </div>
          <div class="column">
            <h3 class="is-size-5 is-uppercase">surface</h3>
            <br>
            <span class="jsmb-detail-focus">{{surface}} m²</span>
          </div>
          <div class="column">
            <h3 class="has-text-dark"><span class="is-uppercase has-text-weight-bold">Niveau de difficulté d’accès</span> <br class="break">Choisissez entre Faible, Moyen ou Difficile</h3>
            <br>
            <div class="field is-horizontal">
              <div class="field-body is-flex" style="justify-content:center">
                <div lass="field is-narrow">
                  <div class="control" style="pointer-events: none;">
                    <label class="radio" v-for="n in 3"  :key="n">
                      <input type="radio" :name="'difficulty-'+n" v-bind:id="n" v-bind:value="n" v-model="difficulty"/>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns jsmb-panel-specificite has-text-centered-mobile" v-for="(spec, index) in specs" :key="'spec-'+index">
          <div class="column is-4">
            <h3 class="is-uppercase">{{spec.title}}</h3>
          </div>
          <div class="column is-8">
            <ul>
              <li v-for="(item, index) in spec.list"  :key="'item-'+index">{{ item }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="columns jsmb-detail-entete-section is-vcentered">
          <div class="column is-12">
            <!-- <h2 class="is-size-4 is-uppercase">emplacement</h2> -->
            <h2 class="is-size-4 is-uppercase">Ports et cale de mise à l’eau</h2>
            <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
          </div>
        </div>
        <div class="columns jsmb-detail-emplacement">
          <!-- <div class="column is-6">
            <p>{{ad.emplacement}}</p>
          </div> -->
          <div class="column is-6" v-if="ad.nearest_hold">
            <div class="column">
              <h3 class="is-size-5 is-uppercase">
                cale de mise à l’eau
                <br>la plus proche
              </h3>
              <br>
              <span class="jsmb-detail-focus">{{ad.nearest_hold}} Km</span>
            </div>
          </div>
          <div class="column is-6" v-if="ad.nearest_harbor">
            <div class="column">
              <h3 class="is-size-5 is-uppercase">
                Port le
                <br>plus proche
              </h3>
              <br>
              <span class="jsmb-detail-focus">{{ad.nearest_harbor}} Km</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Separator from "@/components/template/ui/Separator.vue";
import CommentQuestionModal from "@/components/template/ui/CommentQuestionModal.vue";

export default {
  name: "Annonce",
  components: { Separator, CommentQuestionModal },

  props: {
    user: Object,
    ad: Object,
    space: Object
  },
  data() {
    return {
      isCommentActive: false,
      isQuestionActive: false,
      surface: 0,
      difficulty: 0,
      specs: [],
      profilPicture: require('./../../../assets/profil-modal.png')
    };
  },
  created() {
    this.surface = this.$props.space.length * this.$props.space.width
    this.difficulty = this.$props.space.difficulty
    if (this.$props.ad.securities.length) {
      this.specs.push(
        {
          title: "Sécurité",
          list: this.$props.ad.securities
        }
      )
    }
    if (this.$props.space.options.length) {
      this.specs.push(
        {
          title: "Options",
          list: this.$props.space.options
        }
      )
    }
    if (this.$props.ad.location_conditional.length) {
      this.specs.push(
        {
          title: "Conditions de location",
          list: this.$props.ad.location_conditional
        }
      )
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
.section {
  padding: 1rem 1.5rem;
}
p {
  margin-bottom: 2rem;
}
.jsmb-bateau-stockeur {
  margin-bottom: 1rem;
}
.jsmb-bateau-stockeur-nom {
  margin-top: 1rem;
  font-weight: 100;
}
.jsmb-detail-annonce {
  margin-bottom: 2rem;
}

.jsmb-detail-annonce .column:not(:last-child) {
  border-right: 1px solid #cfcfcf;
}
@media screen and (max-width: 768px) {
  .jsmb-detail-annonce .column:not(:last-child) {
    border-right: 0;
  }
  /*.jsmb-detail-annonce br {
    display: none;
  }*/
  .jsmb-detail-annonce .column,
  .jsmb-detail-emplacement .column {
    margin-bottom: 1rem;
  }
}
span.jsmb-detail-focus {
  color: #1ac3ba;
  font-weight: bold;
  font-size: 1.2rem;
}
.jsmb-panel-specificite {
  padding: 2rem;
  background: #f2f5fb;
  margin-bottom: 1rem;
}
input:not([type="radio"]) {
  margin-bottom: 0.5rem;
  border: none;
}
input[type="radio"] {
  border: 1px solid #df5051;
  padding: 0.5em;
  -webkit-appearance: none;
}
input[type="radio"]:checked {
  background-size: 9px 9px;
  background-color: #df5051;
}
input[type="radio"]:focus {
  outline-color: none;
}
.radio {
  font-size: 0.85rem;
}
.jsmb-panel-specificite:last-of-type {
  margin-bottom: 2rem;
}
.jsmb-panel-specificite h3 {
  font-weight: bold;
}
ul {
  list-style-type: disc;
  list-style-position: inside;
}
.is-messages {
  justify-content: center;
  display: flex;
}

</style>