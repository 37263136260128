<template>
  <div>
    <div v-if="titleModal">
      <h2 class="is-uppercase is-size-4">Disponibilités</h2>
      <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
    </div>
    <form ref="form" @submit.prevent="setOrder">
      <p><strong>{{surface}}</strong> m²<br>pour <span v-if="isPro"><strong>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_per_day_ht)}}</strong> HT/jour<br/>soit </span> <strong>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_per_day_ttc)}}</strong> TTC/jour</p>
      <h3 class="has-text-primary is-uppercase">Type de réservation</h3>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <label class="radio">
              <input type="radio" name="bookingType" v-model="bookingType" value="short"/> Courte durée
            </label>
            <label class="radio">
              <input type="radio" name="bookingType" v-model="bookingType" value="long"/> Longue durée
            </label>
          </div>
        </div>
      </div>
      <h3 class="has-text-primary is-uppercase">Assurance</h3>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <label class="radio insurance">
              <input type="radio" name="insurance"  value="without" v-model="picked" /> Mon bateau est déjà assuré 
            </label>
            <label class="radio">
              <input type="radio" name="insurance"  value="with" v-model="picked" @click="openModal"/> Je souhaite souscrire à l'assurance Jestockemonbateau 
            </label>
          </div>
        </div>
      </div>
      <div style="margin-top:1rem">
          <span class="icon has-text-info">
            <i class="fas fa-info-circle"></i>
          </span>
          <small> En souscrivant l'assurance Jestockemonbateau, vous bénéficiez d'une réduction allant jusqu'à -30 % ! <br> Vous bénéficierez des conseils d’un vrai professionnel de la plaisance et d'un contrat établi sur mesure en fonction de vos besoins.</small>
        </div>
      <div v-if="bookingType==='short'">
        <article class="message is-primary" style="margin-top:1rem">
          <div class="message-body">
            Ce type de location correspond aux réservations d'une durée de 30 jours maximum. Elle s'effectue en nombre de jours.<br>
            Son paiement est <strong>immédiat.</strong>
          </div>
        </article>
      </div>
      <div v-else>
        <article class="message is-primary" style="margin-top:1rem">
          <div class="message-body">
            Ce type de location correspond aux réservations d'une durée de plus de 30 jours. Elle s'effectue en nombre de mois.<br>
            Son paiement est <strong>mensualisé.</strong>
          </div>
        </article>
      </div>
      <h3 class="has-text-primary is-uppercase">Dates de location</h3>
      <div v-if="bookingType==='short'">
        <div class="field-body">
          <div class="field">
            <b-datepicker
              v-model="beginningDate"
              placeholder="Date de début"
              icon-pack="fas"
              icon="calendar-alt"
              required
              :first-day-of-week="1"
              :month-names="this.$store.state.datepickerLocale.monthNames"
              :day-names="this.$store.state.datepickerLocale.dayNames"
              :min-date="this.getTodayDate()"
              @input="enableDateEnd"
              :unselectable-dates="this.unselectableDates"
            ></b-datepicker>
          </div>
          <div class="field">
            <b-datepicker
              v-model="endingDate"
              placeholder="Date de fin"
              icon-pack="fas"
              icon="calendar-alt"
              required
              :first-day-of-week="1"
              :month-names="this.$store.state.datepickerLocale.monthNames"
              :day-names="this.$store.state.datepickerLocale.dayNames"
              :min-date="this.minEndingDate"
              :disabled="isDisabled"
              @input="setPrice"
              :unselectable-dates="this.unselectableDates"
            ></b-datepicker>
          </div>
        </div>
        <div style="margin-top:1rem">
          <span class="icon has-text-info">
            <i class="fas fa-info-circle"></i>
          </span>
          <small>Merci de sélectionner le nombre de jours souhaités pour votre location.</small>
        </div>
      </div>
      <div v-else>
        <div class="field-body">
          <div class="field">
            <b-datepicker
              v-model="beginningDate"
              placeholder="Date de début"
              icon-pack="fas"
              icon="calendar-alt"
              required
              :first-day-of-week="1"
              :month-names="this.$store.state.datepickerLocale.monthNames"
              :day-names="this.$store.state.datepickerLocale.dayNames"
              :min-date="this.getTodayDate()"
              @input="enableNbMonths"
              :unselectable-dates="this.unselectableDates"
            ></b-datepicker>                  
          </div>
          <div class="field" style="display:flex">
            <button type="button" class="button is-primary rmvm br" @click="nbMonths--" :disabled="nbMonths<=1?true:false">–</button>
            <input class="input is-primary datNumber" type="number" placeholder="nombre de mois" min="1" v-model="nbMonths" :disabled="beginningDate===null?true:false">
            <button type="button" class="button is-primary rmvm bl" @click="nbMonths++" :disabled="beginningDate===null?true:false">+</button>
          </div>
        </div>
        <div style="margin-top:1rem">
          <span class="icon has-text-info"> 
            <i class="fas fa-info-circle"></i>
          </span>
          <small>Merci de sélectionner le nombre de mois souhaités pour votre location.<br>(la réservation s'effectuera de date à date).</small>
        </div>
      </div>
      <article v-if="!notError"  class="message is-danger" style="margin-top:1rem">
        <div class="message-body">
          L'emplacement ne peut malheureusement vous être loué, une personne a déjà réservé cette place à ces dates.
        </div>
      </article>
      <h3 class="has-text-primary is-uppercase">surface recherchée</h3>
      <div class="field range-inputs columns is-multiline search-area">
        <div class="column is-12">
          <div class="control">
            <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Longueur</span>
            <b-tooltip always :label="String(length + ' m')" position="is-right">
              <input class="slider" v-model="length" v-bind:min="cursorMin" v-bind:max="cursorLengthMax" type="range">
            </b-tooltip>
          </div>
        </div>
        <div class="column is-12">
          <div class="control">
            <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Hauteur</span>
            <b-tooltip always :label="String(height + ' m')" position="is-right">
              <input class="slider" v-model="height" v-bind:min="cursorMin" v-bind:max="cursorHeightMax" type="range">
            </b-tooltip>
          </div>
        </div>
        <div class="column is-12">
          <div class="control">
            <span class="label is-size-7 is-uppercase has-text-dark has-text-weight-regular">Largeur</span>
            <b-tooltip always :label="String(width + ' m')" position="is-right">
              <input class="slider" v-model="width" v-bind:min="cursorMin" v-bind:max="cursorWidthMax" type="range">
            </b-tooltip>
          </div>
        </div>
      </div>
      <small class="is-uppercase">
        <span v-if="price_ttc">
          <span v-if="!nbMonths">
            {{nbDays}} jour(s)<br/>pour 
            <span v-if="isPro">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ht)}} HT<br/>soit </span>
            {{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ttc)}} TTC
          </span>
          <span v-else>
            {{nbMonths}} mois<br/>pour 
            <span v-if="isPro">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ht)}} HT / mois<br/>soit </span>
            {{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ttc)}} TTC / mois
          </span>
        </span>
        <span v-else>Complétez le formulaire pour voir le prix</span>
      </small>
      <div style="position: relative;">
        <button type="submit" class="button is-normal is-fullwidth is-danger" @click="submitOrder($event)" :class="buttonDoneLoad?'is-loading':''" :disabled="(buttonDoneLoad)||(userId==userIdCurrent)?true:false">Demande de location</button>
      </div>
      <transition name="fade">
        <div class="column is-12" v-if="errorShow===true">
          <article class="message is-danger">
            <router-link :to="{name: 'Account'}" style="text-decoration: none;">
            <div class="message-body">
                    <span class="icon has-text-info">
                      <i class="fas fa-exclamation-circle" style="color: red"></i>
                    </span>
              Vous devez être skipper pour pouvoir effectuer cette opération.<p></p>
              Cliquez sur cette notification pour être redirigé vers votre profil.
            </div>
            </router-link>
          </article>
        </div>
      </transition>
      <b-modal :active.sync="modaleResa" :width="640" scroll="keep">
        <div class="card">
          <div class="card-image">
            <div class="image is-4by3" :style="'background:url('+$props.imgModal+') center center no-repeat; background-size:cover'"></div>
          </div>
          <div class="card-content">
            <div class="content">
              <h2 class="has-text-primary is-size-4">Votre demande de réservation a bien été envoyée.</h2>
              Le stockeur en a été informé.
              Il a 48h pour vous répondre sans quoi le créneaux sera libéré
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal :active.sync="modaleInsurance" :width="640" scroll="keep">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h2 class="has-text-primary is-size-4"> Souscription Assurance Jestockemonbateau</h2>
              Une fois votre réservation terminée et afin de valider votre demande d'assurance.
              <ul> 
                <li>Veuillez remplir le document suivant: <a :href="item.loc" target="_blank">Formulaire d'auto-évaluation Assurance Plaisance</a>  </li>
                <li>L'envoyer à l'adresse mail suivante: <u>agence.sgtasesaintbonnet@axa.fr</u></li>
                <li> Préciser comme objet du mail: <u>Souscription assurance Jestockemonbateau</u> </li>
                </ul>
            </div>
            <br>
            <a @click="openModal"> Revenir à la réservation </a>
          </div>
        </div>
      </b-modal>
    </form>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import { GET_DATEPICKER_FR } from "@/store/modules/template/actions/template.actions"
import { GET_UNAVAILABILITY, ADD_ORDER } from "@/store/modules/database/actions/database.actions"
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'
import Separator from "@/components/template/ui/Separator.vue"
import Account from "../../account/Account";

export default {
  name: "Disponibilite",
  components: { Separator },
  data() {
    var cursorMin = 1;
    return {
      item: {
        title: 'Formulaire d\'auto-évaluation Assurance Plaisance', 
        loc: require('./../../../assets/formulaireRisque.pdf')
        },
      cursorMin: cursorMin,
      cursorStep: 1,
      cursorWidthMax: 50,
      cursorLengthMax: 50,
      cursorHeightMax: 50,

      errorShow : false,

      isDisabled:true,
      minEndingDate: null,
      beginningDate: null,
      endingDate: null,
      unselectableDates: [],

      bookingType: 'short',
      picked: 'without',


      surface: 0,      
      width: 1,
      length: 1,
      height: 1,

      nbDays: null,
      nbMonths: null,

      price_per_day_ht: 0.00,
      price_per_day_ttc: 0.00,
      price_ht: 0.00,
      price_ttc: 0.00,
      isPro: JSON.parse(localStorage.getItem('is-professional')) || false,
      userIdCurrent: JSON.parse(localStorage.getItem('user-id')) || false,

      notError: true,
      buttonDoneLoad: false,

      modaleResa: false,
      isAuth: false,
      modaleInsurance: false,

      number: 10
    };
  },
  props: {
    titleModal: { type: Boolean },
    imgModal : { type: String },
    userId : { type: String },
    space: { type: Object }
  },
  created() {
    this.$store.dispatch(GET_DATEPICKER_FR)
    this.surface = this.$props.space.length * this.$props.space.width;
    this.cursorWidthMax = this.$props.space.width
    this.cursorLengthMax = this.$props.space.length
    this.cursorHeightMax = this.$props.space.height
    
    this.price_per_day_ht = this.$props.space.price_ht;
    this.price_per_day_ttc = this.$props.space.price_ttc;
    
    if (localStorage.getItem('search-boat-data') !== null) {
      this.width = JSON.parse(localStorage.getItem('search-boat-data')).width
      this.length = JSON.parse(localStorage.getItem('search-boat-data')).length
      this.height = JSON.parse(localStorage.getItem('search-boat-data')).height
    }
    if (localStorage.getItem('search-time-data') !== null) {
      this.beginningDate = new Date(JSON.parse(localStorage.getItem('search-time-data')).begin)
      this.enableDateEnd(this.beginningDate)
      this.endingDate = new Date(JSON.parse(localStorage.getItem('search-time-data')).end)
      this.setPrice(this.endingDate)
    }
    this.getUnavailability()
    if (localStorage.getItem('user-id') !== null) {
      this.isAuth = true
    }
  },
  methods: {
    openModal: function () {
      this.modaleInsurance = (this.modaleInsurance == false)? true : false;
    },
    submitOrder: function (event) {
      if (!this.isAuth) {
        event.preventDefault();
        this.$emit('close-modal')
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})

      }/*else{

        if(localStorage.getItem('user-type') === 'stockeur') {
          event.preventDefault();
          this.errorShow = true;
        }
      }*/
    },
    setOrder() {
      const { beginningDate, endingDate, width, length, height, nbDays, nbMonths, bookingType, picked } = this;
      let endDate = undefined
      this.buttonDoneLoad = true

      switch (bookingType) {
        case "short":
          endDate = endingDate.getFullYear()+'-'+(endingDate.getMonth()+1)+'-'+endingDate.getDate()
          break;
      
        case "long":
          endDate = null
          break;
      }

      this.$store.dispatch(
        ADD_ORDER,
        {
          beginDate: beginningDate.getFullYear()+'-'+(beginningDate.getMonth()+1)+'-'+beginningDate.getDate(),
          endDate: endDate,
          width: width,
          length: length,
          height: height,
          nbDays: nbDays,
          nbMonths: nbMonths,
          space_id: this.$route.params.id,
          picked:picked
        }
      ).then(
        resp => {
          const {success, msg, data} = resp.data.response
          if (success) {
            this.getUnavailability()
            this.modaleResa = true
            this.beginningDate = null
            this.endingDate = null
            this.nbDays = undefined
            this.nbMonths = undefined
            this.price_ht = 0.00
            this.price_ttc = 0.00
            this.width = 1
            this.length = 1
            this.height = 1
            this.picked = 'without'
            localStorage.removeItem('search-boat-data')
            localStorage.removeItem('search-time-data')
          }else{
            this.notError = false
            setTimeout(() => {
              this.notError = true
            }, 2500);
          }
          this.buttonDoneLoad = false
        }
      )
    },
    getUnavailability () {
      // Get Unavailability
      this.$store.dispatch(GET_UNAVAILABILITY, {
        space_id: this.$route.params.id
      }).then(resp => {
        this.unselectableDates = resp
      });
    },
    getTodayDate () {
      let date = new Date()
      date.setHours(0,0,0,0)
      date.setDate(date.getDate() + 5)
      return new Date(date)
    },
    enableDateEnd (dateValue) {
      if (dateValue !== null) {
        this.isDisabled = false
        let date = new Date(dateValue)
        date.setHours(0,0,0,1)
        this.minEndingDate = date
      }else{
        this.isDisabled = true
        this.minEndingDate = null
      }
      this.endingDate = null
    },
    enableNbMonths(dateValue) {
      if (dateValue !== null) {
        this.nbMonths = 12
      }else{
        this.nbMonths = null
      }
    },
    setPrice (value) {
      switch (this.bookingType) {
        case "short":
          if (value !== null) {
            this.nbDays = this.dateDiff(this.beginningDate, this.endingDate);
            this.price_ht = this.price_per_day_ht * this.nbDays
            this.price_ttc = this.price_per_day_ttc * this.nbDays
          }else{
            this.price_ht = 0.00
            this.price_ttc = 0.00
          }
          break;
      
        case "long":
          this.price_ht = this.price_per_day_ht * 30
          this.price_ttc = this.price_per_day_ttc * 30
          break;
      }
    },
    dateDiff(date1, date2){
      var diff = {}                           // Initialisation du retour
      var tmp = date2 - date1;

      tmp = Math.floor(tmp/1000);             // Nombre de secondes entre les 2 dates
      diff.sec = tmp % 60;                    // Extraction du nombre de secondes

      tmp = Math.floor((tmp-diff.sec)/60);    // Nombre de minutes (partie entière)
      diff.min = tmp % 60;                    // Extraction du nombre de minutes

      tmp = Math.floor((tmp-diff.min)/60);    // Nombre d'heures (entières)
      diff.hour = tmp % 24;                   // Extraction du nombre d'heures
      
      tmp = Math.floor((tmp-diff.hour)/24);   // Nombre de jours restants
      diff.day = tmp;
      
      return diff.day;
    }
  },
  computed: {
    ...mapGetters(["rentStatus", "rentLoading"]),
  },
  watch: {
    bookingType() {
      this.beginningDate = null
      this.endingDate = null
      this.nbDays = null
      this.nbMonths = null
      this.price_ht = 0.00
      this.price_ttc = 0.00
    },
    nbMonths() {
      this.setPrice()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .jsmb-disponibilite h3,
  .jsmb-disponibilite small {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .jsmb-disponibilite .button {
    margin-top: 2rem;
  }
  .has-background-danger {
    padding: 1rem;
    margin-bottom: 1rem;
    color: #fff;
  }

  .jsmb-disponibilite h3,
  .jsmb-disponibilite small{
      margin-top: 2rem;
      margin-bottom: 1rem;
  }
  .jsmb-disponibilite .button{
      margin-top: 2rem;
  }
  .has-background-danger {
      padding:1rem;
      margin-bottom: 1rem;
      color:#fff;
  }
.insurance {
  margin-left: 0em;
}
  .b-tooltip {
    display: flex;
    z-index: 10;
  }
  .slider {
    -webkit-appearance: none;
    width: 85%;
    height: 10px;
    border-radius: 5px;
    background: #0ec0b6;
    outline: none;
    opacity: 1;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    padding: 0;
  }
  .slider::-webkit-slider-thumb {
    min-height: 20px;
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%; 
    background: #0ec0b6;
    border: 2px solid white;
    cursor: pointer;
  }
  .slider::-moz-range-thumb {
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0ec0b6;
    cursor: pointer;
  }
  .slider::-ms-thumb {
    min-height:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%; 
    background-color: #0ec0b6;
    border:2px solid #fff;
    cursor: pointer;
  }
  .b-tooltip.is-right:after {
    left: calc(85% + 5px + 2px);
    top: 3px;
  }
  .b-tooltip.is-right.is-primary:before {
    left: calc(85% + 2px);
    top: 3px;
  }
  @media screen and (max-width: 768px) {
    .slider {
      width: 85%;
    }
    .b-tooltip.is-right:after {
      left: calc(85% + 5px + 2px);
    }
    .b-tooltip.is-right.is-primary:before {
      left: calc(85% + 2px);
    }
    .slider::-webkit-slider-thumb,
    .slider::-moz-range-thumb {
      width: 20px;
      height: 20px;
    }
    .research-cta .form-top {
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }
  .search-area .control {
    margin-bottom: 1.5rem;
  }
  input[type="radio"]{
    margin-bottom: 0;
  }
  input[type=radio], input[type=checkbox] {
    border: 1px solid #df5051;
    padding: 0.5em;
    -webkit-appearance: none;
  }
  input[type=radio]:checked, input[type=checkbox]:checked {
    background-size: 9px 9px;
    background-color: #df5051;
  }
  .datNumber {
    border-radius:0;
    box-shadow: none;
  }
  .jsmb-disponibilite button.rmvm {
    margin: 0 !important;
  }
  .jsmb-disponibilite .button.rmvm.br {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .jsmb-disponibilite .button.rmvm.bl {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

</style>