<template>
  <div class="other-section">
    <div class="container" id="reserver">
      <div class="columns is-variable is-4 jsmb-detail-entete-section">
        <div class="column is-desktop-5 is-tablet-7">
          <h2 class="is-uppercase is-size-4">Disponibilités</h2>
          <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary" />
          <div class="columns">
            <div class="column is-12 jsmb-disponibilite">
              <Disponibilite :titleModal="false" :imgModal="$props.ad_header.pictures[0].carousel_picture" :space="space" v-if="space !== null" />
            </div>
          </div>
        </div>
        <div class="column is-5" v-if="currentUserId !== user.user_id">
          <br class="is-hidden-desktop is-hidden-tablet">
          <h2 class="is-uppercase is-size-4">Contacter {{ user.first_name }}</h2>
          <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary" />
          <div class="columns is-variable">
            <div class="column is-12">
              <Question :toUser="user" :spaceId="user.space_id" :imgModal="$props.ad_header.pictures[0].carousel_picture" :key="isAuthenticated" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Separator from '@/components/template/ui/Separator.vue'
import Disponibilite from '@/components/detail/detail-sections/Disponibilite.vue'
import Question from "@/components/template/ui/Question.vue";
import { mapGetters } from 'vuex'

export default {
  name: 'Disponibilitecontainer',
  components: {
    Separator,
    Disponibilite,
    Question,
  },
  data () {
    return {
      currentUserId: JSON.parse(localStorage.getItem('user-id'))
    }
  },
  props: {
    ad_header: Object,
    user: Object,
    space: Object,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .other-section {
    padding: 1.5rem 0.5rem
  }
  .jsmb-disponibilite {
    background: #f2f5fb;
    padding: 2rem;
  }
  
</style>