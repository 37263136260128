<template>
  <div v-if="currentUserId !== toUser.user_id">
    <div class="card">
      <div class="card-image">
        <figure class="image is-4by3">
          <img :src="imgModal" alt="Placeholder image">
        </figure>
      </div>
      <div class="">
        <div class="media" v-if="isAuth">
          <div class="media-content">
            <form ref="form" @submit.prevent="addNewText" v-if="!isSend">
              <h2 class="is-size-4 is-uppercase has-text-left">Poser une question</h2>
              <Separator class="push-bottom image is-32x32" color="primary"/>
              <textarea class="textarea" placeholder="Votre message" v-model="message" required></textarea>
              <br>
              <button type="submit" class="button is-primary is-small" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">Envoyer</button>
            </form>
            <div v-else>
              <h2 class="is-size-4 is-uppercase has-text-center">Votre message a bien été envoyé, il va être analysé par nos modérateurs avant d'être remis au bateau stockeur. Merci.</h2>
            </div>
          </div>
        </div>

        <div v-else class="columns is-mobile" style="padding:1rem"> 
          <div class="column is-3-desktop is-3-mobile ">
            <figure class="image is-horizontal-center" :class="$mq | mq({ 
              desktop: 'is-96x96 ',
              tablet: 'is-48x48',
              mobile: 'is-48x48',
              
            })">
              <img
                :src="toUser.profil_picture!==null?toUser.profil_picture:profilPicture"
                :alt="toUser.first_name"
                class="is-rounded"
              >
            </figure>
          </div>
          <div class="column is-9-desktop is-9-mobile">
            <p>Poser une question a votre bateau stockeur {{ toUser.first_name }}</p>
            <p>
              <button class="control is-danger is-small button" @click="createAccount">
                Créer un compte ou Se connecter
              </button>
            </p>
          </div>
        </div>
        <!-- <div class="media" v-else>
          <div class="media-left">
            <figure class="image is-96x96 is-horizontal-center">
              <img
                :src="toUser.profil_picture!==null?toUser.profil_picture:profilPicture"
                :alt="toUser.first_name"
                class="is-rounded"
              >
            </figure>
          </div>
          <div class="media-content">
            <p>Poser une question a votre bateau stockeur {{ toUser.first_name }}</p>
            <p>
              <button class="control is-danger is-small button" @click="createAccount">
                Créer un compte ou Se connecter
              </button>
            </p>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Separator from "@/components/template/ui/Separator.vue";
import { ADD_DISCUSSION } from '@/store/modules/database/actions/database.actions'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'Question',
  components: { Separator },

  props: {
    toUser: { type: Object },
    imgModal : { type: String },
    spaceId: { type: Number }
  },
  data () {
    return {
      isAuth: false,
      message: "",
      isSend: false,
      currentUserId: JSON.parse(localStorage.getItem('user-id')),
      buttonDoneLoad: false,
      profilPicture: require('./../../../assets/profil-modal.png')
    }
  },
  methods: {
    addNewText() {
      this.buttonDoneLoad = true
      // Add Discussion
      this.$store.dispatch(
        ADD_DISCUSSION,
        {space_id: this.$props.spaceId, to: this.$props.toUser.user_id, message: this.message}
      ).then(
        resp => {
          this.isSend = resp
        }
      )
    },
    createAccount() {
      this.$store.dispatch(STATE_MODAL, {'show_modal': true})
    }
  },
  created () {
    if (localStorage.getItem('user-id') !== null) {
      this.isAuth = true
    }
  },
  watch: {
    isSend () {
      if (this.isSend) {
        setTimeout(() => {
          this.isSend = false
          this.message = ""
          this.buttonDoneLoad = false
        }, 1000);
      }
    }
  },
}
</script>

<style scoped lang="css">
.media {
  align-items: center
}
</style>