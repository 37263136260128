<template class="thisDetail">
  <div>
    <Header :ad_header="announcement.ad_header" v-if="announcement !== null" />
    <Annonce :user="announcement.user" :ad="announcement.ad" :space="announcement.space" v-if="announcement !== null" />
    <Map :ad_location="announcement.ad_location" v-if="announcement !== null" />
    <Disponibilitecontainer :ad_header="announcement.ad_header" :user="announcement.user" :space="announcement.space" v-if="announcement !== null" />
    <CommentSimilar :ad_header="announcement.ad_header" :user="announcement.user" :space="announcement.space" v-if="announcement !== null" />
  </div>
</template>

<script>
import Header from '@/components/detail/detail-sections/Header.vue'
import Annonce from '@/components/detail/detail-sections/Annonce.vue'
import Map from '@/components/detail/detail-sections/Map.vue'
import Disponibilitecontainer from '@/components/detail/detail-sections/Disponibilitecontainer.vue'
import CommentSimilar from '@/components/detail/detail-sections/CommentSimilar.vue'
//import Profil from './account-sections/Profil.vue'
//import Separateur from '@/components/template/ui/Separator.vue'

import { STOP_LOADER } from '@/store/modules/template/actions/template.actions'
import { GET_ANNOUNCEMENT_DETAIL } from '@/store/modules/database/actions/database.actions'

export default {
  name: 'Detail',
  components: {
    Header,
    Annonce,
    Map,
    Disponibilitecontainer,
    CommentSimilar,
    //Separateur
  },
  props: {
    announceId: { type: String }
  },
  data () {
    return {
      currentTab: 0,
      announcement: null,
      facebook_picture: '',
      twitter_picture: '',
      surface: 0,
      description: '',
    }
  },
  metaInfo () {
    // let siteName = this.$store.state.globalConstant.SITE_NAME,
    //   baseUrl = this.$store.state.globalConstant.BASE_URL
    
    // let today = new Date(),
    //   options = { year: 'numeric', month: 'long', day: 'numeric' },
    //   dateToday = today.toLocaleDateString('fr-FR', options)

    // let title = this.announcement.ad_header.title+' - '+this.announcement.ad_header.space_type+' '+this.announcement.ad_header.location_type+' '+this.announcement.ad_header.city+' - '+siteName,
    //   description = dateToday+' - '+this.announcement.ad_header.space_type+' '+this.announcement.ad_header.location_type+' '+this.surface+'m². '+this.description
    // return {
    //   titleTemplate: title,
    //   link: [
    //     { rel: 'canonical', href: (baseUrl+this.$route.fullPath) },
    //   ],
    //   meta: [
    //     { 
    //       vmid: 'description', 
    //       name: 'description', 
    //       content: description,
    //     },
    //     {
    //       'property': 'og:title',
    //       'content': title,
    //       'vmid': 'og:title'
    //     },
    //     {
    //       'property': 'og:description',
    //       'content': this.announcement.ad_header.space_type+' '+this.announcement.ad_header.location_type+' '+this.surface+'m². '+this.description,
    //       'vmid': 'og:description'
    //     },
    //     {
    //       'property': 'og:url',
    //       'content': (baseUrl+this.$route.fullPath),
    //       'vmid': 'og:url'
    //     },
    //     {
    //       'property': 'twitter:title',
    //       'content': title,
    //       'vmid': 'twitter:title'
    //     },
    //     {
    //       'property': 'twitter:description',
    //       'content': this.announcement.ad_header.space_type+' '+this.announcement.ad_header.location_type+' '+this.surface+'m². '+this.description,
    //       'vmid': 'twitter:description'
    //     },
    //     {
    //       'property': 'og:image',
    //       'content': this.facebook_picture,
    //       'vmid': 'og:image'
    //     },
    //     {
    //       'property': 'og:image:secure_url',
    //       'content': this.facebook_picture,
    //       'vmid': 'og:image:secure_url'
    //     },
    //     {
    //       'property': 'og:image:alt',
    //       'content': title,
    //       'vmid': 'og:image:alt'
    //     },
    //     {
    //       'property': 'twitter:image',
    //       'content': this.twitter_picture,
    //       'vmid': 'twitter:image'
    //     },
    //   ]
    // }
  },
  created () {
    // Get Announcement
    this.$store.dispatch(
      GET_ANNOUNCEMENT_DETAIL,
      {
        city: this.$route.params.city,
        space_id: this.$route.params.id,
      }
    ).then(
      resp => {
        this.$store.dispatch(STOP_LOADER)
        this.announcement = resp
        this.facebook_picture = resp.ad_header.pictures[0].facebook_picture
        this.twitter_picture = resp.ad_header.pictures[0].twitter_picture
        this.surface = resp.space.length*resp.space.width
        this.description = resp.ad.description
      }
    )
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    p {
      white-space: pre-wrap;
      white-space: -moz-pre-wrap;
      white-space: -pre-wrap;
      white-space: -o-pre-wrap;
      word-wrap: break-word;
    }
    
</style>

<style lang="css">
    .push-bottom {
        margin-bottom: 1rem;
    }
    h2 {
        font-weight: bold;
    }
    .is-horizontal-center {
        margin: 0 auto
    }
    h3 {
        color: #818080;
    }

</style>
