<template>
  <div class="section">
    <div class="container jsmb-commentaires" v-if="comments && comments.length !== 0">
      <div class="columns jsmb-detail-entete-section is-vcentered">
        <div class="column is-8">
          <h2 class="is-uppercase is-size-4">Commentaires</h2>
          <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column jsmb-comments is-6-desktop is-6-tablet is-12-mobile" v-for="(comment, index) in comments"  :key="'comment-'+index">
          <div class="columns">
            <div class="column is-4">
              <figure class="image is-96x96 is-horizontal-center">
                <img
                  :src="comment.from.profil_picture!==null?comment.from.profil_picture:profilPicture"
                  class="is-rounded"
                  :alt="comment.from.name"
                  :title="comment.from.name"
                >
              </figure>
            </div>
            <div class="column is-8">
              <h3 class="has-text-primary is-uppercase">{{comment.from.name}}</h3>
              <StarRating :stars="comment.star" showGrey></StarRating>
              <p>{{comment.message}}</p>
            </div>
          </div>
        </div>
        <div class="column" v-if="perPageComments < totalComments">
          <b-pagination
            :total="totalComments"
            :current.sync="currentComments"
            :order="order"
            :size="size"
            :simple="true"
            :rounded="isRounded"
            :per-page="perPageComments"
            @change="commentPage"
          ></b-pagination>
        </div>
      </div>
    </div>
    <div class="container" v-if="similars && similars.length !== 0">
      <div class="columns jsmb-detail-entete-section is-vcentered">
        <div class="column is-8">
          <h2 class="is-uppercase is-size-4">locations similaires</h2>
          <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
        </div>
      </div>
      <div class="columns is-variable is-3">
        <div class="column is-4-desktop is-4-tablet is-12-mobile" v-for="(similar, index) in similars"  :key="'similar-'+index">
          <div class="jsmb-similar">
            <figure class="image is-3by2">
              <img :src="similar.similar_picture" :alt="similar.title" :title="similar.title">
              <figcaption v-if="similar.is_professional" class="is-uppercase is-size-6">Professionnel</figcaption>
            </figure>
            <div class="jsmb-similar-desc">
              <h3 class="is-uppercase">{{similar.space_type}} / EMPLACEMENT {{similar.location_type}}</h3>
              <h2 class="has-text-primary is-size-4">
								<router-link :to="{name: 'Detail', params: {id:similar.space_id, city:similar.city.toLowerCase()}}" tag="a" push>
									{{similar.announcement_title}} - {{similar.space_title}}
								</router-link>
							</h2>
              <small>{{similar.city}} - {{similar.zip_code}}</small>
              <p class="is-pulled-left">
                <strong>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(similar.price_ht)}}</strong> HT/jour<br/>
                <strong>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(similar.price_ttc)}}</strong> TTC/jour
              </p>
              <p class="is-pulled-right">
                <strong>{{similar.surface}} m</strong>
                <sup>2</sup>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="column" v-if="perPageSimilars < totalSimilars">
        <b-pagination
          :total="totalSimilars"
          :current.sync="currentSimilars"
          :order="order"
          :size="size"
          :simple="true"
          :rounded="isRounded"
          :per-page="perPageSimilars"
          @change="similarPage"
        ></b-pagination>
      </div>
    </div>
    <div class="has-background-primary jsmb-article-sticky">
      <div class="columns is-vcentered">
        <div class="column is-8 is-flex is-vcentered" style="border-right:1px solid #eee">
          <button
            style="margin-right:1rem"
            class="button is-danger"
            @click="showModalDisponibilities"
          >Disponibilités</button>
          <small
            class="is-uppercase"
          >{{ad_header.space_type}} / EMPLACEMENT {{ad_header.location_type}}</small>
          <h3 class="is-uppercase">{{ad_header.announcement_title}} - {{ad_header.space_title}}</h3>
        </div>
        <div class="column is-flex">
          <strong style="line-height: 1.5rem;">{{surface}} m²</strong>
          <span v-if="isPro">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ht)}} HT/jour</span>
          <span>{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price_ttc)}} TTC/jour</span>
          <a @click="goto" class="button">Réserver</a>
        </div>
      </div>
    </div>
    <button class="showMobile button is-danger" @click="showModalDisponibilities">Disponibilités</button>
    <b-modal :active.sync="isCardModalActive" has-modal-card class="modalDisponibilite">
      <div class="card">
        <Disponibilite :titleModal="true" :space="space" @close-modal="closeModalDisponibilities" v-if="space !== null" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Separator from "@/components/template/ui/Separator.vue";
import StarRating from '@/components/template/ui/StarRating.vue'
import Disponibilite from "@/components/detail/detail-sections/Disponibilite.vue";
import { GET_COMMENT_ANNOUNCEMENT, GET_ANNOUNCEMENT_SIMILAR } from "@/store/modules/database/actions/database.actions";

export default {
  name: "Commentaire",

  components: {
    Separator,
    StarRating,
    Disponibilite
  },
  props: {
    ad_header: Object,
    user: Object,
    space: Object
  },
  data() {
    return {
      comments: [],
      allSimilars: [],
      similars: [],

      surface: 0,
      price_ht: 0.00,
      price_ttc: 0.00,

      totalComments: 0,
      totalSimilars: 0,
      currentComments: 1,
      currentSimilars: 1,
      perPageComments: 4,
      perPageSimilars: 3,
      order: "is-right",
      size: "",
      isRounded: false,

      profilPicture: require('./../../../assets/profil-modal.png'),

      isCardModalActive: false,
      isPro: JSON.parse(localStorage.getItem('is-professional')) || false
    };
  },
  methods: {
    showModalDisponibilities(){
      this.isCardModalActive = true
    },
    closeModalDisponibilities(){
      this.isCardModalActive = false
    },
    commentPage(value) {
      this.$store.dispatch(GET_COMMENT_ANNOUNCEMENT, {
        user_id: this.$props.user.user_id,
        perPage: this.perPageComments,
        page: value
      })
      .then(resp => {
        this.comments = resp.items;
        this.totalComments = resp.total;
      });
    },
    similarPage(value) {
      value = value - 1;
      this.similars = this.allSimilars.slice(
        value * this.perPageSimilars,
        value * this.perPageSimilars + this.perPageSimilars
      );
    },
    goto() {
      setTimeout(function() {
        var element = document.getElementById("reserver");
        element.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      }, 500);
    }
  },
  created() {
    // Get Comment Announcement
    this.$store.dispatch(GET_COMMENT_ANNOUNCEMENT, {
      user_id: this.$props.user.user_id,
      perPage: this.perPageComments,
      page: 1
    }).then(resp => {
      this.comments = resp.items;
      this.totalComments = resp.total;
    });
    // Get Announcement Similar
    this.$store.dispatch(GET_ANNOUNCEMENT_SIMILAR, {
      space_id: this.$route.params.id
    }).then(resp => {
      this.allSimilars = resp;
      this.totalSimilars = this.allSimilars.length;
      if (this.totalSimilars > this.perPageSimilars) {
        this.similars = this.allSimilars.slice(0, this.perPageSimilars);
      } else {
        this.similars = this.allSimilars;
      }
    });
    this.surface = this.$props.space.length * this.$props.space.width;
    this.price_ht = this.$props.space.price_ht;
    this.price_ttc = this.$props.space.price_ttc;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
	.jsmb-commentaires {
		margin-bottom: 10rem;
	}
	.jsmb-commentaires figure {
		margin-bottom: 1rem;
	}
	.jsmb-comments {
		margin-bottom: 2rem;
	}
	@media screen and (min-width: 430px) and (max-width: 768px) {
		.columns.jsmb-panel-specificite,
		.jsmb-comments .columns:not(.is-desktop) {
			display: flex;
		}
		.jsmb-panel-specificite .column.is-4,
		.jsmb-panel-specificite .column.is-4-tablet,
		.jsmb-comments .column.is-4,
		.jsmb-comments .column.is-4-tablet {
			-webkit-box-flex: 0;
			-ms-flex: none;
			flex: none;
			width: 33.33333%;
		}
		.jsmb-panel-specificite .column.is-8,
		.jsmb-panel-specificite .column.is-8-tablet,
		.jsmb-comments .column.is-8,
		.jsmb-comments .column.is-8-tablet {
			-webkit-box-flex: 0;
			-ms-flex: none;
			flex: none;
			width: 66.66667%;
		}
	}
	.jsmb-similar-desc {
		padding: 1rem 1rem 3.5rem;
	}
	.jsmb-similar {
		border: 1px solid #c8c8c8;
		margin-bottom: 1rem;
		position: relative;
	}
	.jsmb-similar a:after {
		content: "";
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.jsmb-similar small {
		display: block;
		margin-bottom: 1.5rem;
	}
	.showMobile {
		display: none;
	}
	@media screen and (max-width: 768px) {
		.jsmb-article-sticky {
			display: none;
		}
		.showMobile {
			display: block;
			position: fixed;
			left: 1rem;
			bottom: 1rem;
      z-index: 40;
		}
	}
	a.has-text-text {
		text-decoration: underline;
		margin-top: 3rem;
		display: block;
	}
	.jsmb-article-sticky {
		position: fixed;
		z-index: 900;
		bottom: 0;
		left: 0;
		right: 0;
		color: #fff;
	}
	.jsmb-article-sticky {
		padding: 1rem 0;
	}
	.jsmb-article-sticky .column {
		padding: 0 1rem;
	}
	.jsmb-article-sticky .column:not(.is-2) {
		justify-content: space-between;
	}
	.jsmb-article-sticky strong,
	.jsmb-article-sticky h3 {
		color: #fff;
	}
	.jsmb-prix-selection {
		background: transparent;
		border: none;
		color: #fff;
		outline: 0;
	}
	.modal {
		z-index: 1000;
	}
	.modal .card {
		padding: 2rem;
	}
	.is-flex {
		align-items: center;
	}
  .jsmb-similar figcaption {
    position: absolute;
    z-index: 20;
    top:0;
    right:0;
    background:#df5051;
    color:#fff;
    padding: 0.5rem 1rem
  }



</style>
<style>

	.modalDisponibilite .button {
		margin-top: 0rem;
	}
	.modalDisponibilite h3,
	.modalDisponibilite .jsmb-disponibilite small {
		margin-top: 2rem;
		margin-bottom: 1rem;
	}
  .modalDisponibilite .animation-content {
      overflow-y: scroll;
      
  }
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: portrait) {
    .modalDisponibilite {
      overflow-y: scroll;
      /* padding-top: 20rem; */
    }
    .modal-close {
      background: #df5051 !important;
      z-index: 10;
    }
}
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: landscape) {
  	.modalDisponibilite {
      overflow-y: scroll;
      /* padding-top: 20rem; */
    }
    .modal-close {
      background: #df5051 !important;
      z-index: 10;
    }
}
</style>
