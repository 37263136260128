<template>
	<div>
		<div class="columns jsmb-header is-vcentered">
			<div class="column is-4 is-12-mobile jsmb-info-annonce">
				<div class="jsmb-firstPanel">
					<small class="is-uppercase">{{ad_header.space_type}} / EMPLACEMENT {{ad_header.location_type}}</small>
					<Separator class="image is-48x48 jsmb-svg" color="primary" />
					<h1 class="is-size-3 is-uppercase">{{ad_header.announcement_title}} - {{ad_header.space_title}}</h1>
					<small>{{ad_header.city}} - {{ad_header.zip_code}}</small>
				</div>
				<div class="dropdown is-up jsmb-share" :class="{ 'is-active' : isDropDownActive }">
					<div class="dropdown-trigger">
						<button class="button" aria-haspopup="true" aria-controls="dropdown-menu" @click="changeDropDownState">
							<i class="fas fa-share-alt"></i>
						</button>
					</div>
					<div class="dropdown-menu" id="dropdown-menu" role="menu">
						<div class="dropdown-content">
							<!-- Insérer lien de partage : base + url de l'article-->
							<a href="https://www.facebook.com/sharer/sharer.php?u=" target="_blank" class="button jsmb-inline">
								<i class="fab fa-facebook"></i>
							</a>
							<a href="https://twitter.com/home?status=" target="_blank" class="button jsmb-inline">
								<i class="fab fa-twitter"></i>
							</a>
							<a href="https://www.linkedin.com/shareArticle?mini=true&url=&title=&summary=&source=" target="_blank" class="button jsmb-inline">
								<i class="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<FavoriteButton :spaceId="ad_header.space_id" :isFavorite="ad_header.is_favorite" :key="$store.getters.userId" />
			</div>
			<div class="column is-8 is-12-mobile">
				<Carousel v-bind:pictures="urlPictures"/>
			</div>
		</div>
	</div>
</template>

<script>
	import Separator from '@/components/template/ui/Separator.vue'
	import Carousel from '@/components/template/ui/Carousel.vue'
	import FavoriteButton from '@/components/template/ui/FavoriteButton.vue'

	export default {
		name: 'Header',
		components: {
			Separator,
			Carousel,
			FavoriteButton
		},

		props: {
			ad_header: Object,
		},
		data () {
			return {
				isDropDownActive: false,
				changeStateFav: false,

				urlPictures: [],
			}
		},
		methods: {
			changeDropDownState() {
				this.$data.isDropDownActive = this.$data.isDropDownActive ? false : true;
			},
		},
		created () {
			this.$props.ad_header.pictures.forEach(picture => {
				this.urlPictures.push(
					{
						mainUrl: picture.carousel_picture,
						srcSetSm: picture.carousel_responsive_picture,
						imgCarouselAlt:'Location place à sec '+this.$props.ad_header.city+' - '+this.$props.ad_header.zip_code
					}
				)
			});
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
	.jsmb-header {
		background: #f2f5fb;
		align-items: stretch;
		position: relative;
	}
	.jsmb-firstPanel {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
	}
	.jsmb-header h1 {
		font-weight: 100;
	}
	.jsmb-info-annonce {
		padding:2rem;
		position: relative;
	}
	.jsmb-share {
		position:absolute;
		right: 60px;
		bottom:10px;
	}
	.jsmb-inline {
		display: block;
		margin-bottom: 0rem;
	}
	.jsmb-inline:last-of-type {
		margin-bottom: 0;
	}
	.jsmb-share .button {
		background: transparent;
		border: none;
	}
	.jsmb-share .button:hover {
		color:#27c6bd
	}
	.jsmb-share .dropdown-content {
		background: transparent;
		box-shadow: none;
		width: fit-content;
	}
</style>