<template>
  <!-- <div ref="thisCarousel" class="carousel carousel-animate-slide carousel-animated">
    <div class='carousel-container'>
      <div class='carousel-item has-background is-active' v-for="(imageCarousel, index) in imagesCarousel" :key="'image-carousel-'+index">
        <img class="is-background" :src="imageCarousel.mainUrl" :srcset="imageCarousel.srcSetSm+' 320w, '+imageCarousel.mainUrl+' 640w'" alt="JeStockeMonBateau - Stockage de bateaux entre particuliers" title="JeStockeMonBateau - Stockage de bateaux entre particuliers" width="" height="" />
      </div>
    </div>
    <div class="carousel-navigation is-overlay">
      <div class="carousel-nav-left">
        <i class="fa fa-chevron-left" aria-hidden="true"></i>
      </div>
      <div class="carousel-nav-right">
        <i class="fa fa-chevron-right" aria-hidden="true"></i>
      </div>
    </div>
  </div> -->
  <section class="hero is-medium has-carousel">
    <div id="jsmb-carousel-detail" class="carousel">
      <div class="" v-for="(imageCarousel, index) in imagesCarousel" :key="'image-carousel-'+index">
        <img class="is-background" :src="imageCarousel.mainUrl" :srcset="imageCarousel.srcSetSm+' 320w, '+imageCarousel.mainUrl+' 640w'" alt="JeStockeMonBateau - Stockage de bateaux entre particuliers" title="JeStockeMonBateau - Stockage de bateaux entre particuliers" width="" height="" />
      </div>
    </div>
  </section>
</template>

<script>
import bulmaCarousel from './../../../../node_modules/bulma-carousel/dist/js/bulma-carousel.min.js'

export default {
  name: 'Carousel',
  props: {
    pictures: { type: Array}
  },
  data () {
    return {
      imagesCarousel : [],
      bulmaCarousel: {}
    }
  },
  mounted () {
    // this.bulmaCarousel = new bulmaCarousel(this.$refs.thisCarousel)

    if (this.imagesCarousel.length > 1) {
      this.carousel = bulmaCarousel.attach('#jsmb-carousel-detail', {
        slidesToScroll: 1,
        slidesToShow: 1,
        pagination: false,
        infinite: true
      })
    }

  },
  created () {
    // Vérifie le navigateur est IE
    if (!!window.MSInputMethodContext && !!document.documentMode) {
      this.imagesCarousel.push(this.$props.pictures[0])
    }else{
      this.imagesCarousel = this.$props.pictures;
    }
  }
}
</script>

<style scoped lang="css">
  @import './../../../../node_modules/bulma-carousel/dist/css/bulma-carousel.min.css';
  .carousel {
    overflow: hidden;
  }
</style>