var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-fullwidth jsmb-map"},[_c('div',{staticClass:"section"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns jsmb-detail-entete-section is-vcentered"},[_c('div',{staticClass:"column is-12"},[_c('h2',{staticClass:"is-uppercase is-size-4"},[_vm._v("Découvrez les cales de mises à l'eau les plus proches")]),_c('Separator',{staticClass:"push-bottom image is-32x32 jsmb-svg",attrs:{"color":"primary"}})],1)])])]),_c('gmap-map',{ref:"map",staticStyle:{"width":"100%","height":"300px"},attrs:{"center":_vm.center,"zoom":14,"options":{
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: true
        }}},[_c('gmap-info-window',{attrs:{"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_vm._v(_vm._s(_vm.infoContent))]),_vm._l((_vm.cales),function(cale,index){return _c('gmap-marker',{key:index,attrs:{"position":{ lat: Number(cale.latitude), lng: Number(cale.longitude) },"clickable":true},on:{"click":function($event){return _vm.toggleInfoWindow(cale, index)}}})})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }