<template>
  <div v-if="currentUserId !== toUserId">
    <button :class="'button '+this.classBtnModal+' is-small'" @click="submitCommentQuestion($event)">{{text}}</button>
    <b-modal :active.sync="isModalActive">
      <div class="card">
        <div class="card-content">
          <form ref="form" @submit.prevent="addNewText" v-if="!isSend">
            <h2 class="is-size-4 is-uppercase has-text-left">{{text}}</h2>
            <Separator class="push-bottom image is-32x32" color="primary"/>
            <div v-if="$props.action==='comment'">
              <StarRating v-model="star" showGrey/>
              <br>
            </div>
            <textarea class="textarea" placeholder="Votre message" v-model="message" required></textarea>
            <br>
            <button type="submit" class="button is-primary is-small" :class="buttonDoneLoad?'is-loading':''" :disabled="buttonDoneLoad?true:false">{{text}}</button>
          </form>
          <div v-else>
            <h2 class="is-size-4 is-uppercase has-text-center">{{msgConfirm}}</h2>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Separator from "@/components/template/ui/Separator.vue";
import StarRating from '@/components/template/ui/StarRating.vue'
import { ADD_COMMENT, ADD_DISCUSSION } from '@/store/modules/database/actions/database.actions'
import { STATE_MODAL } from '@/store/modules/landing/actions/landing.actions'

export default {
  name: 'CommentQuestionModal',
  components: {
    Separator,
    StarRating
  },
  props: {
    text: { type: String, default: "Laisser un commentaire" },
    action: { type: String, default: "comment" },
    toUserId: { type: Number },
    spaceId: { type: Number }
  },
  data () {
    return {
      isAuth: false,
      isModalActive: false,
      classBtnModal: "is-primary",
      star: 0,
      message: "",
      msgConfirm: "",
      isSend: false,
      currentUserId: JSON.parse(localStorage.getItem('user-id')),
      buttonDoneLoad: false,
    }
  },
  methods: {
    addNewText() {
      this.buttonDoneLoad = true
      switch (this.$props.action) {
        case 'comment':
          // Add Comment
          this.$store.dispatch(
            ADD_COMMENT,
            {to: this.$props.toUserId, message: this.message, star: this.star}
          ).then(
            resp => {
              const {success} = resp.data.response
              if (success) {
                this.isSend = true
              }
            }
          )
          break;
      
        case 'question':
          // Add Discussion
          this.$store.dispatch(
            ADD_DISCUSSION,
            {space_id: this.$props.spaceId, to: this.$props.toUserId, message: this.message}
          ).then(
            resp => {
              this.isSend = resp
            }
          )
          break;
      }
    },
    submitCommentQuestion: function (event) {
      if (this.isAuth) {
        this.isModalActive = true
      }else{
        this.$store.dispatch(STATE_MODAL, {'show_modal': true})
      }
    }
  },
  created () {
    if (localStorage.getItem('user-id') !== null) {
      this.isAuth = true
    }
    switch (this.$props.action) {
      case 'comment':
        this.classBtnModal = "is-primary"
        this.msgConfirm = "Votre commentaire a bien été posté."
        break;
    
      case 'question':
        this.classBtnModal = "is-danger"
        this.msgConfirm = "Votre message a bien été envoyé, il va être analysé par nos modérateurs avant d'être remis au bateau stockeur. Merci."
        break;
    }
  },
  watch: {
    isSend () {
      if (this.isSend) {
        setTimeout(() => {
          this.isSend = false
          this.message = ""
          this.isModalActive = false
          this.buttonDoneLoad = false
        }, 1000);
      }
    }
  },
}
</script>

<style scoped lang="css"></style>