<template>
    <div class="is-fullwidth jsmb-map">
        <div class="section">
            <div class="container">
                <div class="columns jsmb-detail-entete-section is-vcentered">
                    <div class="column is-12">
                    <h2 class="is-uppercase is-size-4">Découvrez les cales de mises à l'eau les plus proches</h2>
                    <Separator class="push-bottom image is-32x32 jsmb-svg" color="primary"/>
                    </div>
                </div>
            </div>
        </div>
        <gmap-map
            :center="center"
            :zoom="14"
            style="width:100%; height:300px;"
            ref="map"
            :options="{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: true
            }"
        >
            <gmap-info-window :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">{{infoContent}}</gmap-info-window>
            <gmap-marker :key="index" v-for="(cale, index) in cales" :position="{ lat: Number(cale.latitude), lng: Number(cale.longitude) }" :clickable="true" @click="toggleInfoWindow(cale, index)"/>
        </gmap-map>
    </div>
</template>
<script>
import Separator from '@/components/template/ui/Separator.vue'
import { GET_NEAREST_CALES } from '@/store/modules/database/actions/database.actions'
import { mapState } from 'vuex'

export default {
    name: 'Map',
    components: {Separator},

	props: {
        ad_location: Object
    },
	data () {
        return {
            center: { lat: 45.508, lng: -73.587 },
            cales: [],

            infoContent: '',
            infoWindowPos: null,
            infoWinOpen: false,
            currentMidx: null,
        }
	},
	mounted() {
		window.vueGoogleMapsInit(google)
		let latitude = parseFloat(this.$props.ad_location.latitude) + 0.001
		let longitude = parseFloat(this.$props.ad_location.longitude) + 0.001
        this.center = { lat: latitude, lng: longitude }
        
        // CALES les plus proche
        this.$store.dispatch(
            GET_NEAREST_CALES, {longitude: this.$props.ad_location.longitude, latitude: this.$props.ad_location.latitude}
        ).then(
            resp => {
                this.cales = resp
            }
        )
    },
    methods: {
        toggleInfoWindow(marker, idx) {
            this.infoWindowPos = { lat: Number(marker.latitude), lng: Number(marker.longitude) };
            this.infoContent = marker.name;
            if (this.currentMidx == idx) {
                // check if its the same marker that was selected if yes toggle
                this.infoWinOpen = !this.infoWinOpen;
            }else{
                // if different marker set infowindow to open and reset current marker index
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        }
    }
}
</script>

<style scoped lang="css">
	.jsmb-map {
		margin-bottom: 2rem;
	}
    .jsmb-map .section {
        padding-bottom: 1rem;
    }
	p {
		color: #818080;
	}
</style>